import { Link } from "react-router-dom";
import thumb1 from "../images/thumbicon1.jpg";
import thumb2 from "../images/thumbicon2.jpg";
import thumb3 from "../images/thumbicon3.jpg";
import thumb4 from "../images/thumbicon4.jpg";
import thumb5 from "../images/thumbicon5.jpg";

function Nav() {
    return ( 
        <>
        <div className="menubar"> 
          {/***menu start */}
            <div className="menu"> 
            <Link to='#'>&#9776;Menu  </Link> 
            </div>
            {/***menu start */}
            <div className="menuslide">
                <div className="menutab"> 
                  <Link to='#'> 
                      <img src={thumb1 } />
                      <p>Pregnancy Food</p>
                  </Link>
                  <Link to='#'> 
                      <img src={thumb2 } />
                    <p>Baby Food</p>
                  </Link>
                  <Link to='#'> 
                      <img src={thumb3 } />
                    <p>Diabetes Free Food</p>
                  </Link>
                  <Link to='#'> 
                      <img src={thumb4 } />
                    <p>Fitness and Sports  <br /> Nutrition Food</p>
                  </Link>
                  <Link to='#'> 
                      <img src={thumb5 } />
                    <p>Menstruation and Menopause <br /> Relief Food</p>
                  </Link>
                </div>

            </div>
        </div>

        </>

     );
}

export default Nav;