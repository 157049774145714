import { Link } from "react-router-dom";
import Header from "../header";
import Footer from "../footer";
import HomeSliderMain from "../addincluders/HomeSliderMain";
import ProfileNav from "./ProfileNav";
import { Helmet, HelmetProvider } from 'react-helmet-async';

function ProfileNutriScoreCertification () {
    return (
     <>
      <HelmetProvider>
         <Helmet>
             <title> Nutri Score Certification by Wholix India: Evaluating Nutritional Quality for Healthier
             Choices</title>
            <meta name="keywords" content="Nutri Score Certification, nutritional evaluation, food quality rating, scoring system,
consumer guidance, transparency, industry improvement, healthier food choices, nutrition
certification, nutritional quality, food labeling"/>
            <meta name="description" content="Wholix India&#39;s Nutri Score Certification provides a clear, reliable rating system to evaluate
food products&#39; nutritional quality. With scores from A to E, it offers transparency, consumer
guidance, and encourages industry improvements. Aligned with Wholix s mission, this
certification helps consumers make informed dietary choices and promotes healthier
lifestyles."/>
             <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1"/>  
         </Helmet>
         </HelmetProvider>
     <main>
         <section className="head"> 
         <Header /> 
            <div className="container">
            <ProfileNav />
            
            </div> 
         </section> 
        
        <section className="content">
        <article className="container text-justify">
            <h4>WHOLIX NUTRI SCORE CERTIFICATION </h4>
            <hr /> 
           
                <p>The Nutri Score Certification by Wholix India is a comprehensive rating system designed to evaluate and certify the nutritional quality of food products. This certification aims to provide clear, reliable, and easily understandable nutritional information to consumers, helping them make informed dietary choices. Here are the key elements of the Nutri Score Certification:</p>
                <br />
                <br />
                <div className='row nutriscoresbox'>
                      <div className='col-12 col-md-4'>
                         <div className="card"> 
                             <div className="card-body">
                             <h5>Nutritional Evaluation </h5>
               
               <ul className="dottedlist">
                   <li>  <p> The Nutri Score Certification assesses the nutritional content of food products, including factors such as the presence of essential nutrients (e.g., vitamins, minerals, fiber) and the levels of less desirable components (e.g., sugars, salt, saturated fats).</p> <br /></li>
                   <li>  <p> It takes into account both positive and negative nutritional factors to provide a balanced view of the product's overall nutritional quality.</p> <br /></li> 

               </ul>
              
                            
                            </div>
                        </div> 
                      </div>
                      <div className='col-12 col-md-4'>
                         <div className="card"> 
                             <div className="card-body">
                             <h5>Scoring System </h5>
               
               <ul className="dottedlist">
                   <li>  <p> Products are assigned a Nutri Score ranging from A to E, with A being the highest rating &#40;indicating the healthiest choice&#41; and E being the lowest.</p> <br /></li>
                   <li>  <p> The scoring system is based on a standardized algorithm that considers various nutritional criteria, ensuring consistency and objectivity in the evaluation process.</p> <br /></li> 

               </ul>
              
                            
                            </div>
                        </div> 
                      </div>
                      <div className='col-12 col-md-4'>
                         <div className="card"> 
                             <div className="card-body">
                             <h5>Transparency and Trust: </h5>
               
               <ul className="dottedlist">
                   <li>  <p> The Nutri Score Certification process is transparent, with detailed information about the evaluation criteria and scoring methodology made available to consumers.</p> <br /></li>
                   <li>  <p> Wholix India's commitment to transparency helps build trust with consumers, who can rely on the certification to make healthier food choices.</p> <br /></li> 

               </ul>
              
                            
                            </div>
                        </div> 
                      </div>
                      <div className='col-12 col-md-4'>
                         <div className="card"> 
                             <div className="card-body">
                             <h5>Consumer Guidance: </h5>
               
               <ul className="dottedlist">
                   <li>  <p> The Nutri Score label on certified products provides a quick and easy way for consumers to assess the nutritional quality of foods at a glance.
                   </p> <br /></li>
                   <li>  <p> This visual guidance supports healthier eating habits by encouraging consumers to choose products with higher Nutri Scores.</p> <br /></li> 

               </ul>
            
                            
                            </div>
                        </div> 
                      </div>
                      <div className='col-12 col-md-4'>
                         <div className="card"> 
                             <div className="card-body">
                             <h5>Encouraging Industry Improvement: </h5>
               
               <ul className="dottedlist">
                   <li>  <p> By adopting the Nutri Score Certification, Wholix India encourages food manufacturers to improve the nutritional quality of their products.
                   </p> <br /></li>
                   <li>  <p> The certification serves as an incentive for brands to reformulate their products to achieve higher Nutri Scores, contributing to overall public health improvements.</p> <br /></li> 

               </ul>
             
                            
                            </div>
                        </div> 
                      </div>
                      <div className='col-12 col-md-4'>
                         <div className="card"> 
                             <div className="card-body">
                             <h5>Alignment with Wholix's Mission:</h5>
               
               <ul className="dottedlist">
                   <li>  <p> The Nutri Score Certification aligns with Wholix India's mission to revolutionize the health, wellness, and fitness industry by promoting better nutrition and healthier lifestyles.
                   </p> <br /></li>
                   <li>  <p> It complements Wholix's broader efforts to address malnutrition, improve the nutritional status of various population groups, and provide specialized nutrition for specific health needs.
                   </p> <br /></li> 

               </ul>
                            
                            </div>
                        </div> 
                      </div> 
                      
                 </div>     
                 
                 <br /><br />
                <p>Overall, the Nutri Score Certification by Wholix India is a crucial component of their commitment to enhancing nutritional awareness and promoting healthier food choices among consumers.
                </p>
                <br /><br />
                <a  className="buttonbrand"  href="/our-profile/">Get your product certified</a>

            </article>
            <article>
                
            </article>

        </section>
        
        <Footer />

         </main>
    
    </> 
    
);
}

export default ProfileNutriScoreCertification;