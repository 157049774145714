import chatboticon from '../images/chatbot.gif';

function ChatBot() {
    return ( 
        <>  
            
            <div className="chatboticon"> 
                    <img src={ chatboticon }  />
                </div>        
        </>
     );
}

export default ChatBot;