import { Link } from "react-router-dom";
import Header from "../header";
import Footer from "../footer";
import HomeSliderMain from "../addincluders/HomeSliderMain";
import ProfileNav from "./ProfileNav";
import { Helmet, HelmetProvider } from 'react-helmet-async';

function ProfileWholixConsultancyCenter () {
    return (
     <>
      <HelmetProvider>
         <Helmet>
             <title> Wholix Consultancy Center: Expert Guidance for Optimal Health &amp; Nutrition</title>
            <meta name="keywords" content="Wholix Consultancy Center, expert nutrition advice, personalized wellness solutions,
pregnancy nutrition, baby food consultancy, diabetes nutrition, menstrual relief, menopause
relief, fitness nutrition, sports supplements, fertility nutrition, mental wellness, diet planning,
health and wellness consultancy"/>
            <meta name="description" content="Discover Wholix Consultancy Center for expert, personalized nutrition and wellness
solutions. From pregnancy and diabetes management to fitness and mental wellness, our
specialists offer tailored advice and comprehensive support for every life stage. Join us for
convenient access to top-notch nutritional guidance and improve your health and well-being
with Wholix."/>
             <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1"/>  
         </Helmet>
         </HelmetProvider>
     <main>
         <section className="head"> 
         <Header /> 
            <div className="container">
            <ProfileNav />
            
            </div> 
         </section> 
          
        <section className="content">
        <article className="container text-justify">
            <h4>WHOLIX CONSULTANCY CENTER : Guiding You Towards Optimal Health and Nutrition</h4>
            <hr /> 
                <h5>Introduction </h5>
                <p> Welcome to the Wholix Consultancy Center, your premier destination for expert advice and
personalized solutions in the fields of nutrition and wellness. We are committed to
empowering individuals and professionals with the knowledge and skills they need to make
informed decisions and promote healthier lifestyles. Our consultancy services are designed to
provide comprehensive guidance and practical strategies, ensuring that our clients are
well-equipped to navigate the evolving wellness landscape.</p>
                <br />
                <h5>Pregnancy and Baby Food Consultancy </h5>
                <p><b>Specialized Nutrition for Mothers and Babies</b></p>
                <p>Services: Consult with our experts to receive personalized nutritional plans for expecting
mothers, focusing on essential nutrients and dietary practices for a healthy pregnancy. Get
tailored advice on baby food preparation to ensure your child starts life with nutritious
homemade meals that support their growth and development.</p>
<br />
                <h5>Diabetes Nutrition Consultancy </h5>
                <p><b>Managing Blood Sugar and Promoting Overall Health</b></p>
                <p>Services: Work with our specialists to develop customized meal plans and dietary strategies
that help manage diabetes effectively. Learn about the best foods and snacks to maintain
stable blood sugar levels and support your overall health.</p>
<br />
<h5>Menstruation and Menopause Relief Consultancy </h5>
                <p><b>Supporting Hormonal Health and Well-Being</b></p>
                <p>Services: Receive expert guidance on how to use diet to alleviate symptoms associated with
menstruation and menopause. Get personalized recommendations for maintaining hormonal
balance and overall well-being through tailored nutritional support.</p>
<br />
<h5>Fitness and Sports Nutrition Consultancy </h5>
                <p><b>Fueling Performance and Recovery</b></p>
                <p>Services: Collaborate with our sports nutrition consultants to optimize your diet for athletic
performance, endurance, and muscle recovery. Access the latest insights on sports
supplements and dietary strategies that enhance your fitness and athletic achievements.</p>
<br />

<h5>Wholix Fertility Consultancy </h5>
                <p><b>Enhancing Reproductive Health with Nutrient-Rich Foods</b></p>
                <p>Services: Get customized nutritional advice to support reproductive health for both men and
women. Our consultants provide evidence-based recommendations for incorporating key
nutrients into your diet to enhance fertility and support your reproductive wellness.</p>
<br />
<h5>Wholix Mental Wellness Consultancy </h5>
                <p><b>Nourishing Mind and Body for Mental Clarity</b></p>
                <p>Services: Consult with our experts to understand the connection between diet and mental
health. Receive personalized advice on the best foods and supplements to support cognitive
function and emotional balance, promoting overall mental wellness.</p>
<br />

<h5>Nutritional Science and Diet Planning Consultancy </h5>
                <p><b>Creating Balanced Diet Plans for Optimal Health</b></p>
                <p>Services: Work with our nutrition scientists to gain a deep understanding of nutritional
principles. Get professional help in creating customized diet plans that cater to various health
needs and dietary preferences, ensuring balanced and effective nutrition.</p>
<br /> 
 
 
 <p> 
<h5>Why Choose Wholix Consultancy Center?</h5>
<ul>
  <li><p><b>Expert Guidance:</b> Benefit from our team of experienced consultants who provide
  practical and science-backed advice to help you make informed dietary choices.</p> <br /></li>
  <li><p><b>Personalized Solutions:</b> Receive customized nutritional recommendations tailored to
  your specific health needs and lifestyle goals.</p> <br /></li>
  <li><p><b>Comprehensive Support:</b> Access a wide range of specialized consultancy services
  designed to address nutritional needs across different life stages and health concerns.</p> <br /></li> 
<li><p><b>Convenient Access:</b> Enjoy easy access to expert advice and personalized nutrition
plans, making it convenient for you to maintain your health and well-being.</p> <br /></li> 
 
                </ul>

</p>
<p>Join Wholix Consultancy Center today and experience the convenience and quality of having
expert nutritional guidance delivered right to your doorstep. Wholix  your partner in health,
wellness & fitness supporting your nutritional needs at every stage of life.</p>
<br />



            </article>
            <article>
                
            </article>

        </section>
        
        <Footer />

         </main>
    
    </> 
    
);
}

export default ProfileWholixConsultancyCenter;