import * as React from 'react';
import { lazy } from 'react';
import './App.css';
import Home from './Home';
import { BrowserRouter , Route, Routes } from 'react-router-dom'; 
import PageNotFound from './components/PageNotFound';
import Loader from './Loader.js'; 
import ProfileAboutusWhatWeOffer from './profilecomponents/ProfileAboutusWhatWeOffer.js';
import ProfileAboutusOurValues from './profilecomponents/ProfileAboutusOurValues.js';
import ProfileDeliveryLogistics from './profilecomponents/ProfileDeliveryLogistics.js';
import ProfileLeadershipPrinciples from './profilecomponents/ProfileLeadershipPrinciples.js';
import ProfileWholixConsultancyCenter from './profilecomponents/ProfileWholixConsultancyCenter.js';
import ProfileWholixNutritionalCenters from './profilecomponents/ProfileWholixNutritionalCenters.js';
import ProfileWholixPrime from './profilecomponents/ProfileWholixPrime.js';
import ProfileNutriScoreCertification from './profilecomponents/ProfileNutriScoreCertification.js';
import ProfileNutriScoreApplications from './profilecomponents/ProfileNutriScoreApplications.js';
 
const ProductDetails = lazy(() => import('./components/ProductDetails.js')); 
const OurProfile = lazy(() => import('./components/OurProfile.js')); 
const ContactUs = lazy(() => import('./components/ContactUs.js')); 
const SallerHome = lazy(() => import('./sallerpage/SallerHome.js')); 
const ProfileHome = lazy(() => import('./profilecomponents/ProfileHome.js')); 
const ProfileAboutusWhoWeAre = lazy(() => import('./profilecomponents/ProfileAboutusWhoWeAre.js')); 

function App() {
  return (
    
    <>
 
    <BrowserRouter> 
          <Routes> 
              <Route path="/" element={
                  <React.Suspense fallback={<><Loader /></>}>
                  <Home />
                  </React.Suspense>
                }  />
                 <Route path="/ourprofile/" element={
                  <React.Suspense fallback={<><Loader /></>}>
                  <OurProfile />
                  </React.Suspense>
                }  />
                 <Route path="/contact-us/" element={
                  <React.Suspense fallback={<><Loader /></>}>
                  <ContactUs />
                  </React.Suspense>
                }  />



                <Route path="/our-profile/" element={
                  <React.Suspense fallback={<><Loader /></>}>
                  <ProfileHome />
                  </React.Suspense>
                }  />
                <Route path="/who-we-are/" element={
                  <React.Suspense fallback={<><Loader /></>}>
                  <ProfileAboutusWhoWeAre />
                  </React.Suspense>
                }  />
                 <Route path="/what-we-offer/" element={
                  <React.Suspense fallback={<><Loader /></>}>
                  <ProfileAboutusWhatWeOffer />
                  </React.Suspense>
                }  />
                 <Route path="/our-values/" element={
                  <React.Suspense fallback={<><Loader /></>}>
                  <ProfileAboutusOurValues />
                  </React.Suspense>
                }  />

                <Route path="/leadership-principles/" element={
                  <React.Suspense fallback={<><Loader /></>}>
                  <ProfileLeadershipPrinciples />
                  </React.Suspense>
                }  />

                <Route path="/wholix-prime/" element={
                  <React.Suspense fallback={<><Loader /></>}>
                  <ProfileWholixPrime />
                  </React.Suspense>
                }  />
                <Route path="/wholix-nutritional-centers/" element={
                  <React.Suspense fallback={<><Loader /></>}>
                  <ProfileWholixNutritionalCenters />
                  </React.Suspense>
                }  />
                <Route path="/delivery-logistics/" element={
                  <React.Suspense fallback={<><Loader /></>}>
                  <ProfileDeliveryLogistics />
                  </React.Suspense>
                }  />
                <Route path="/wholix-consultancy-center/" element={
                  <React.Suspense fallback={<><Loader /></>}>
                  <ProfileWholixConsultancyCenter />
                  </React.Suspense>
                }  />
                 <Route path="/wholix-nutri-score-certification/" element={
                  <React.Suspense fallback={<><Loader /></>}>
                  <ProfileNutriScoreCertification />
                  </React.Suspense>
                }  />
                <Route path="/wholix-nutri-score-applications/" element={
                  <React.Suspense fallback={<><Loader /></>}>
                  <ProfileNutriScoreApplications />
                  </React.Suspense>
                }  />

 
                <Route path="/seller/" element={
                  <React.Suspense fallback={<><Loader /></>}>
                  <SallerHome />
                  </React.Suspense>
                }  /> 

                 <Route path="/product-detail/" element={
                  <React.Suspense fallback={<><Loader /></>}>
                  <ProductDetails />
                  </React.Suspense>
                }  />  

              <Route path="*" element={<PageNotFound />} />
            </Routes>  
            
        </BrowserRouter>  
       </>

  );
}

export default App;
