import { Link } from "react-router-dom";
import Header from "../header";
import Footer from "../footer";
import HomeSliderMain from "../addincluders/HomeSliderMain";
import ProfileNav from "./ProfileNav";
import { Helmet, HelmetProvider } from 'react-helmet-async';

function ProfileNutriScoreApplications () {
    return (
     <>
     <HelmetProvider>
         <Helmet>
             <title> Applications of Nutri Score Certification: Enhancing Nutritional Quality Across Industries</title>
            <meta name="keywords" content="Nutri Score applications, nutritional quality, food and beverage industry, dairy products,
bakery, snack foods, processed foods, health and wellness, baby nutrition, pet food, catering,
retail, e-commerce"/>
            <meta name="description" content="The Nutri Score Certification by Wholix India is crucial across diverse industries, including
food and beverage, dairy, bakery, snacks, processed foods, health and wellness, baby
nutrition, pet food, and catering. It aids in providing transparent nutritional information,
guiding consumer choices, and enhancing product quality in grocery stores, restaurants, and
online retailers."/>
             <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1"/>  
         </Helmet>
         </HelmetProvider>
     <main>
         <section className="head"> 
         <Header /> 
            <div className="container">
            <ProfileNav />
            
            </div> 
         </section> 
        
        <section className="content">
        <article className="container text-justify">
            <h4>WHOLIX NUTRI SCORE APPLICATIONS </h4>
            <hr />  
                <p>The Nutri Score is applicable in various industries where nutritional quality and consumer health are of paramount importance. These industries include:
                </p>
                <br />
                <br />
                <div className='row nutriscoresbox2'>
                      <div className='col-12 col-md-4'>
                         <div className="card"> 
                             <div className="card-body">
                             <h5>Food and Beverage Industry: </h5>
               
               <ul className="dottedlist">
                   <li>  <p>Packaged Foods: Ready-to-eat meals, snacks, cereals, canned goods, and frozen foods.</p> <br /></li>
                   <li>  <p> Beverages: Soft drinks, juices, dairy beverages, and plant-based milk alternatives.
                   </p> <br /></li> 

               </ul>
              
                            
                            </div>
                        </div> 
                      </div>
                      <div className='col-12 col-md-4'>
                         <div className="card"> 
                             <div className="card-body">
                             <h5>Dairy Industry:
                             </h5>
               
               <ul className="dottedlist">
                   <li>  <p> Milk and Milk Products: Cheese, yogurt, butter, and ice cream.</p> <br /></li>
                   <li>  <p> Plant-Based Dairy Alternatives: Soy, almond, oat, and other non-dairy milk products.
                   </p> <br /></li> 

               </ul>
              
                            
                            </div>
                        </div> 
                      </div>
                      <div className='col-12 col-md-4'>
                         <div className="card"> 
                             <div className="card-body">
                             <h5>Bakery and Confectionery:</h5>
               
               <ul className="dottedlist">
                   <li>  <p> Baked Goods: Bread, cakes, pastries, and biscuits</p> <br /></li>
                   <li>  <p> Confectionery: Chocolates, candies, and sweets.</p> <br /></li> 

               </ul>
              
                            
                            </div>
                        </div> 
                      </div>
                      <div className='col-12 col-md-4'>
                         <div className="card"> 
                             <div className="card-body">
                             <h5>Snack Foods: </h5>
               
               <ul className="dottedlist">
                   <li>  <p> Savory Snacks: Chips, nuts, pretzels, and popcorn.
                   </p> <br /></li>
                   <li>  <p> Healthy Snacks: Protein bars, granola bars, and dried fruit.</p> <br /></li> 

               </ul>
            
                            
                            </div>
                        </div> 
                      </div>
                      <div className='col-12 col-md-4'>
                         <div className="card"> 
                             <div className="card-body">
                             <h5>Processed and Packaged Foods:
                             </h5>
               
               <ul className="dottedlist">
                   <li>  <p> Convenience Foods: Instant noodles, soups, and ready-to-cook meals.
                   </p> <br /></li>
                   <li>  <p> Packaged Meals: Pre-prepared meals that require minimal preparation.</p> <br /></li> 

               </ul>
             
                            
                            </div>
                        </div> 
                      </div>
                      <div className='col-12 col-md-4'>
                         <div className="card"> 
                             <div className="card-body">
                             <h5>Health and Wellness Products:</h5>
               
               <ul className="dottedlist">
                   <li>  <p> Nutritional Supplements: Vitamins, minerals, protein powders, and meal replacement shakes.
                   </p> <br /></li>
                   <li>  <p> Dietary Products: Products designed for specific dietary needs, such as low-calorie, low-sugar, or gluten-free options. 
                   </p> <br /></li> 

               </ul>
                            
                            </div>
                        </div> 
                      </div> 

                      <div className='col-12 col-md-4'>
                         <div className="card"> 
                             <div className="card-body">
                             <h5>Baby and Infant Nutrition:</h5>
               
               <ul className="dottedlist">
                   <li>  <p> Baby Foods: Infant formula, baby cereals, purees, and snacks.
                   </p> <br /></li>
                   <li>  <p>Toddler Nutrition: Foods specifically designed for the nutritional needs of toddlers.
                   </p> <br /></li> 

               </ul>
                            
                            </div>
                        </div> 
                      </div> 

                      <div className='col-12 col-md-4'>
                         <div className="card"> 
                             <div className="card-body">
                             <h5>Pet Food Industry:</h5>
               
               <ul className="dottedlist">
                   <li>  <p>Pet Nutrition: Dog and cat food, including dry kibble, wet food, and treats.
                   </p> <br /></li> 

               </ul>
                            
                            </div>
                        </div> 
                      </div> 

                      <div className='col-12 col-md-4'>
                         <div className="card"> 
                             <div className="card-body">
                             <h5>Catering and Food Service Industry:</h5>
               
               <ul className="dottedlist">
                   <li>  <p>Institutional Catering: Meals provided in schools, hospitals, and corporate canteens.
                   </p> <br /></li> 
                   <li>  <p>Restaurant Chains: Nutritional information for menu items in fast food and casual dining restaurants.
                   </p> <br /></li> 

               </ul>
                            
                            </div>
                        </div> 
                      </div> 
                      <div className='col-12 col-md-4'>
                         <div className="card"> 
                             <div className="card-body">
                             <h5>Retail and E-commerce:  </h5>
               
               <ul className="dottedlist">
                   <li>  <p>Grocery Stores: Shelf labeling to help consumers make healthier choices.
                   </p> <br /></li> 
                   <li>  <p>Online Food Retailers: Nutritional scores displayed on product pages to guide online shoppers.
                   </p> <br /></li> 

               </ul>
                            
                            </div>
                        </div> 
                      </div> 

                      
                 </div>     
                 
                 <br /><br />
                <p>The Nutri Score is a versatile tool that can be applied across these industries to help improve public health by encouraging healthier food choices and promoting transparency in nutritional information. 
                </p>
                <br /><br />
               
            </article>
            <article>
                
            </article>

        </section>
        
        <Footer />

         </main>
    
    </> 
    
);
}

export default ProfileNutriScoreApplications;