import { Link } from "react-router-dom";

function ProfileNav() {
    return ( 
        <>
                        <nav className="navbar navbar-expand-lg navbar-light">
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
  <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
    
    <ul className="navbar-nav mr-auto mt-2 mt-lg-0">

    <li className="nav-item active">
       <Link  className="nav-link"  to="/our-profile/"><i className="fas fa-home"></i></Link>
         
      </li>
      
      <li className="nav-item dropdown">
        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          About Us
        </a>

        
        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
        <li> <Link  className="dropdown-item"  to="/who-we-are/">Who we are</Link></li> 
          <li><Link  className="dropdown-item"  to="/our-values/">Our Values</Link></li>
          <li><a  className="dropdown-item"  href="/what-we-offer/">What We Offer</a></li>
       
        </ul> 
      </li>
      
      <li className="nav-item active">
       <a  className="nav-link"  href="/leadership-principles/">Leadership Principles</a>
         
      </li>
      <li className="nav-item dropdown">
        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownwholixprime" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Wholix Prime
        </a>

        
        <ul className="dropdown-menu" aria-labelledby="navbarDropdownwholixprime">
          <li> <Link  className="dropdown-item"  to="/wholix-prime/">Wholix Prime</Link> </li>
          <li><Link  className="dropdown-item"  to="/wholix-nutritional-centers/">Wholix Nutritional Centers</Link></li> 
        </ul> 
      </li> 
    
      <li className="nav-item active">
       <Link  className="nav-link"  to="/delivery-logistics/">Delivery & Logistics</Link>
         
      </li>
      <li className="nav-item active">
       <Link  className="nav-link"  to="/wholix-consultancy-center/">Wholix Consultancy Center</Link>
         
      </li>
      
      <li className="nav-item dropdown">
        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownnutriscore" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Nutri-Score
        </a>

        
        <ul className="dropdown-menu" aria-labelledby="navbarDropdownnutriscore">
          <li> <Link  className="dropdown-item"  to="/wholix-nutri-score-certification/">Nutri-Score Certification</Link> </li>
          <li><Link  className="dropdown-item"  to="/wholix-nutri-score-applications/">Nutri-Score Applications</Link></li> 
        </ul> 
      </li> 
       
      <div className="access2 md-flex md-flex-row md-justify-end items-center"> 
                            <Link className="buttonbrand"  to="/login/">Login</Link>
                            <Link className="buttonbrand"  to="/signup/">Signup</Link>  
                            <Link className="buttonbrand cartbtn"  to="/signup/">Cart <i className="fas fa-shopping-cart"></i></Link> 
                    
                    </div>
      { /***
      <li className="nav-item dropdown">
        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Dropdown link
        </a>

        
        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <li><a className="dropdown-item" href="#">Action</a></li>
          <li><a className="dropdown-item" href="#">Another action</a></li>
          <li className="dropdown-submenu"><a className="dropdown-item dropdown-toggle" href="#">Submenu</a>
            <ul className="dropdown-menu">
              <li><a className="dropdown-item" href="#">Submenu action</a></li>
              <li><a className="dropdown-item" href="#">Another submenu action</a></li>


              <li className="dropdown-submenu"><a className="dropdown-item dropdown-toggle" href="#">Subsubmenu</a>
                <ul className="dropdown-menu">
                  <li><a className="dropdown-item" href="#">Subsubmenu action</a></li>
                  <li><a className="dropdown-item" href="#">Another subsubmenu action</a></li>
                </ul>
              </li>
              <li className="dropdown-submenu"><a className="dropdown-item dropdown-toggle" href="#">Second subsubmenu</a>
                <ul className="dropdown-menu">
                  <li><a className="dropdown-item" href="#">Subsubmenu action</a></li>
                  <li><a className="dropdown-item" href="#">Another subsubmenu action</a></li>
                </ul>
              </li>



            </ul>
          </li>
        </ul> 
      </li>***/}
    </ul>
    
  </div>
</nav>

        </>

     );
}

export default ProfileNav;