import { InfinitySpin } from "react-loader-spinner";

function Loader() {
    return (

      <>
      <section className="slider_section ">  
      <center>
        <div className="preloader-wrap">
          <span><InfinitySpin
        visible={true}
        width="200"
        color="#4fa94d"
        ariaLabel="infinity-spin-loading"
        /></span>
        

        </div>
           

      </center>
   </section>

      </>
   
    )
  }

  export default Loader;