import { Link } from "react-router-dom";
import Header from "../header";
import Footer from "../footer";
import HomeSliderMain from "../addincluders/HomeSliderMain";
import ProfileNav from "./ProfileNav";
import { Helmet, HelmetProvider } from 'react-helmet-async';

function ProfileWholixPrime () {
    return (
     <>
      <HelmetProvider>
         <Helmet>
             <title> Wholix Prime: Fast, Free Delivery on Essential Nutrition Products | Wide Selection &amp;
             Hassle-Free Shopping</title>
            <meta name="keywords" content="Wholix Prime, fast delivery, free delivery, essential nutrition, baby food, pregnancy food,
high protein food, sports supplements, hassle-free delivery, curated nutrition products,
Wholix Prime badge, next-day delivery, budget-friendly nutrition, comprehensive nutrition
choices"/>
            <meta name="description" content="Wholix Prime offers fast, free delivery on essential nutrition products like baby food,
pregnancy essentials, high protein food, and sports supplements. Enjoy free delivery on
orders over INR 299 and access a wide selection of curated products with the Wholix Prime
badge for next-day delivery."/>
             <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1"/>  
         </Helmet>
         </HelmetProvider>
     <main>
         <section className="head"> 
         <Header /> 
            <div className="container">
            <ProfileNav />
            
            </div> 
         </section> 
       
        <section className="content">
        <article className="container text-justify">
            <h4>WHOLIX PRIME : Your Essential Nutrition, Delivered Fast </h4>
            <hr />
                <p> Wholix Prime offers more than just fast delivery—it’s your lifeline to essential nutrition
products delivered hassle-free to your doorstep. Whether you need baby food, pregnancy
food, high protein food, or sports supplements, Wholix Prime ensures prompt delivery of
your products.</p>
                <br /> 
                <ul>
                    <li>  <p><b>Free Delivery Over INR 299 Purchase:<br /></b>
                    
                    <ul>
  <li><p><b>Effortless Access:</b> Enjoy free delivery on orders over INR 299, ensuring
  essential nutrition is always within reach without extra costs.</p> <br /></li>
  <li><p><b>Budget-Friendly:</b> Save on delivery charges, focusing on your nutritional
  needs without worrying about shipping fees. (T&C apply over some products)</p> <br /></li> 
                </ul>
                    
                    
                    </p> <br /></li>

<li>  <p><b>Wide Selection of Products :<br /></b> 
<ul>
  <li><p><b>Comprehensive Choices:</b> Access a curated selection of nutrition products,
from baby food and pregnancy essentials to sports supplements and adolescent
nutrition.</p> <br /></li>
  <li><p><b>Wholix Prime Badge:</b> Look for the Wholix Prime badge to easily identify
  products eligible for next-day delivery.</p> <br /></li> 
                </ul>

</p> <br /></li>
 
 

                </ul>

            </article>
            <article>
                
            </article>

        </section>
        
        <Footer />

         </main>
    
    </> 
    
);
}

export default ProfileWholixPrime;