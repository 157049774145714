import { Link } from "react-router-dom";
import Header from "../header";
import Footer from "../footer";
import HomeSliderMain from "../addincluders/HomeSliderMain";
import ProfileNav from "./ProfileNav";
import { Helmet, HelmetProvider } from 'react-helmet-async';

function ProfileAboutusOurValues () {
    return (
     <>
     <HelmetProvider>
         <Helmet>
             <title>Our Values at Wholix: Quality, Innovation, Customer Focus, Sustainability, and Community</title>
            <meta name="keywords" content="Wholix values, quality nutrition, innovation in health, customer-centric approach,
sustainability, ethical practices, community connection, nutrition enthusiasts, healthcare
experts, eco-friendly products, premium supplements, elderly nutrition, pregnancy-safe
foods, ethical producers"/>
            <meta name="description" content="Discover Wholix&#39;s core values: Quality First, Innovation and Excellence, Customer-Centric
Approach, Sustainability and Responsibility, and Community and Connection. We prioritize
high standards, cutting-edge solutions, customer satisfaction, ethical practices, and fostering a
vibrant nutrition community."/>
             <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1"/>  
         </Helmet>
         </HelmetProvider>
     <main>
         <section className="head"> 
         <Header /> 
            <div className="container">
            <ProfileNav />

            </div> 
         </section> 
         
        <section className="content">
        <article className="container text-justify">
            <h4>OUR VALUES</h4>
            <hr /> 
                <h5>Quality First </h5>
                <p> At Wholix, we believe that quality is paramount. We carefully select our partners and
products to ensure that every item on our platform meets our high standards. Whether you’re
looking for pregnancy-safe foods, premium supplements, or elderly nutrition solutions,
you can trust Wholix to deliver the best.</p>
                <br />
                <h5>Innovation and Excellence</h5>
                <p> We are driven by a relentless pursuit of excellence and innovation. Our team is constantly
exploring new ways to enhance our offerings and improve your shopping experience. From
state-of-the-art nutritional solutions to cutting-edge health technology, we are committed to
being at the forefront of the nutrition industry.</p>
<br />
                <h5>Customer-Centric Approach</h5>
                <p> At Wholix, our customers are at the heart of everything we do. We listen to your needs,
respond to your feedback, and strive to exceed your expectations. Our goal is to make your
shopping experience as enjoyable and convenient as possible, from browsing to delivery. We
are passionate about creating a seamless shopping experience that ensures you can access
the nutrition you need, effortlessly and efficiently.</p>
<br />
                <h5>Sustainability and Responsibility</h5>
                <p> We believe in the power of sustainability and ethical practices. At Wholix, we are dedicated
to promoting sustainable practices and supporting ethical producers. By offering eco-friendly
products and reducing our environmental footprint, we aim to make a positive impact on the
planet and contribute to a healthier, more sustainable future.</p>

<br />
                <h5>Community and Connection</h5>
                <p> Wholix is not just a marketplace; it's a community of nutrition enthusiasts, healthcare
experts, and customers committed to quality, innovation, and well-being. We celebrate
diversity and foster connections between consumers, farmers, and artisans. Through our
platform, we create opportunities for people to learn, share, and enjoy food in new and
exciting ways.</p>


            </article>
            <article>
                
            </article>

        </section>
        
        <Footer />

         </main>
    
    </> 
    
);
}

export default ProfileAboutusOurValues;