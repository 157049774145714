import slide1 from '../images/slide1.jpg';  
import slide2 from '../images/slide2.jpg';  
import slide3 from '../images/slide3.jpg';  
import slide4 from '../images/slide4.jpg';  
import slide5 from '../images/slide5.jpg';  
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from "react-router-dom";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Keyboard, Pagination, Navigation, breakpoints, Autoplay  } from 'swiper/modules';
function HomeSliderMain () {
    return ( 
        <>  
         <Swiper
         loop={true}
            slidesPerView={1} 
            keyboard={{
              enabled: true,
            }}
            autoplay={{
              delay: 5000,
              disableOnInteraction: true
          }}
            pagination={{
              clickable: true,
            }}
            navigation={false}
            modules={[Autoplay, Keyboard, Pagination, Navigation]}
            className="mySwiper"
          >
        
            <SwiperSlide>
            <img src={slide1} />
            </SwiperSlide>
            <SwiperSlide>
            <img src={slide2} />
            </SwiperSlide>
            <SwiperSlide>
            <img src={slide3} />
            </SwiperSlide>
            <SwiperSlide>
            <img src={slide4} />
            </SwiperSlide>
            <SwiperSlide>
            <img src={slide5} />
            </SwiperSlide>
             
          
      </Swiper>
        </>

     );
}

export default HomeSliderMain;