import { Link } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import Nav from "./addincluders/Nav";
import banner1 from './images/banner1.jpg';
import productone from './images/productone.jpg';
import FeatureProductSlider from "./addincluders/FeatureProductSlider";
import ChatBot from "./addincluders/ChatBot";
 

function Home () {
    return (  
        <>
        <main> 
         <section className="head"> 
            <Header />  
         </section> 
         <article>   
         <div className="container">
            <Nav /> 
            </div> 
         <div className="container">
            <br />
         <img src={ banner1 } style={{ width : "100%"}} />
            </div> 
         </article> 
        <section className="content">
            <article className="container text-center">
               <h3>Introduction to Wholix India </h3>
                <p className="text-center"> 
Wholix India is India's first certified health, wellness, and fitness platform, offering specialized products and expert consultations. Through our "Ask Our Expert" feature, users can seek advice from doctors and dietitians. Our extensive product range includes baby food, sports nutrition, weight loss solutions, diabetes management, herbal supplements, vitamins and minerals, men's health, women's health, immunity and wellness products, and digestive care items. Wholix is pioneering the introduction of the Nutri-Score in India, enabling consumers to make transparent and informed decisions about their nutrition. With a commitment to quality and innovation, Wholix is setting new standards in the health and wellness e-commerce landscape in India. </p>
                <br />
                <a  className="buttonbrand"  href="/our-profile/">Read More</a>
            </article>
            <br />
            <hr />
            <br />
            <article className="container">
                <h4>Feature Products</h4>
                <br />
                <article className="products"> 

                <FeatureProductSlider />
                </article> 
                <br />
                <Link  className="btn btn-warning"  to="#">Load More</Link>
            </article>
            <hr />
            <article className="container">
                <h4>Our Products</h4>
                <br />
                <article className="products">
                        <div className="card">
                        <img className="card-img-top" src={ productone } alt="Card image cap" />
                        <div className="card-body">
                            <h5 className="card-title">Nutrition Pack</h5>
                            <p className="card-text"> Pure Nutrition Multivitamin for Women consists of vitamins</p> 
                            <br />
                            <div className="btn-group" role="group" aria-label="First group">
                                <button type="button" className="btn btn-outline-light"> <i className="fas fa-angle-right"></i> View</button>
                                <button type="button" className="btn btn-outline-light"><i className="fas fa-cart-plus"></i></button>
                            
                                <button type="button" className="btn btn-outline-light"><i className="fas fa-heart"></i></button>
                            </div>
                        </div>
                        </div>

                        <div className="card">
                        <img className="card-img-top" src={ productone } alt="Card image cap" />
                        <div className="card-body">
                            <h5 className="card-title">Nutrition Pack</h5>
                            <p className="card-text"> Pure Nutrition Multivitamin for Women consists of vitamins</p> 
                            <br />
                            <div className="btn-group" role="group" aria-label="First group">
                                <button type="button" className="btn btn-outline-light"> <i className="fas fa-angle-right"></i> View</button>
                                <button type="button" className="btn btn-outline-light"><i className="fas fa-cart-plus"></i></button>
                            
                                <button type="button" className="btn btn-outline-light"><i className="fas fa-heart"></i></button>
                            </div>
                        </div>
                        </div>

                         
                        <div className="card">
                        <img className="card-img-top" src={ productone } alt="Card image cap" />
                        <div className="card-body">
                            <h5 className="card-title">Nutrition Pack</h5>
                            <p className="card-text"> Pure Nutrition Multivitamin for Women consists of vitamins</p> 
                            <br />
                            <div className="btn-group" role="group" aria-label="First group">
                                <button type="button" className="btn btn-outline-light"> <i className="fas fa-angle-right"></i> View</button>
                                <button type="button" className="btn btn-outline-light"><i className="fas fa-cart-plus"></i></button>
                            
                                <button type="button" className="btn btn-outline-light"><i className="fas fa-heart"></i></button>
                            </div>
                        </div>
                        </div>

                             
                        <div className="card">
                        <img className="card-img-top" src={ productone } alt="Card image cap" />
                        <div className="card-body">
                            <h5 className="card-title">Nutrition Pack</h5>
                            <p className="card-text"> Pure Nutrition Multivitamin for Women consists of vitamins</p> 
                            <br />
                            <div className="btn-group" role="group" aria-label="First group">
                                <button type="button" className="btn btn-outline-light"> <i className="fas fa-angle-right"></i> View</button>
                                <button type="button" className="btn btn-outline-light"><i className="fas fa-cart-plus"></i></button>
                            
                                <button type="button" className="btn btn-outline-light"><i className="fas fa-heart"></i></button>
                            </div>
                              
                        </div>
                        </div>

                         
                        <div className="card">
                        <img className="card-img-top" src={ productone } alt="Card image cap" />
                        <div className="card-body">
                            <h5 className="card-title">Nutrition Pack</h5>
                            <p className="card-text"> Pure Nutrition Multivitamin for Women consists of vitamins</p> 
                             
                            <br />
                            <div className="btn-group" role="group" aria-label="First group">
                                <button type="button" className="btn btn-outline-light"> <i className="fas fa-angle-right"></i> View</button>
                                <button type="button" className="btn btn-outline-light"><i className="fas fa-cart-plus"></i></button>
                            
                                <button type="button" className="btn btn-outline-light"><i className="fas fa-heart"></i></button>
                            </div>
                        </div>
                        </div> 
     
                </article>
                <br />
                <Link  className="btn btn-warning"  to="#">Load More</Link>
                
            </article>
            

        </section>
        
        <Footer />
            <ChatBot />
         </main>
        </>


    );
}

export default Home ;
