import { Link } from "react-router-dom";
import Header from "../header";
import Footer from "../footer";
import HomeSliderMain from "../addincluders/HomeSliderMain";
import ProfileNav from "./ProfileNav";
import { Helmet, HelmetProvider } from 'react-helmet-async';

function ProfileDeliveryLogistics () {
    return (
     <>
     <HelmetProvider>
         <Helmet>
             <title> Wholix: Timely &amp; Reliable Nutrition Delivery with Advanced Order Processing | Quality,
             Efficiency, and Trust</title>
            <meta name="keywords" content="Wholix delivery, efficient fulfilment, reliable last mile delivery, advanced order processing,
3PL providers, nutritional products, automated systems, smart labels technology, warehouse
management, data-driven insights, comprehensive nutrition range, seamless shopping
experience, sustainable practices, authentic products, Wholix 5-star rating system, quality
nutrition, health and wellness"/>
            <meta name="description" content="Wholix partners with 3PL providers for efficient fulfilment and reliable last mile delivery.
Leveraging advanced technologies, we ensure timely, accurate delivery of high-quality
nutrition products. Enjoy a seamless shopping experience with our diverse product range,
commitment to sustainability, and trusted Wholix 5-Star Rating System, ensuring the best for
your health and wellness."/>
             <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1"/>  
         </Helmet>
         </HelmetProvider>
     <main>
         <section className="head"> 
         <Header /> 
            <div className="container">
            <ProfileNav />
            
            </div> 
         </section> 
         
        <section className="content">
        <article className="container text-justify">
            <h4>DELIVERY & LOGISTICS :Ensuring Your Nutritional Products Reach You Swiftly </h4>
            <hr />
                <p> At Wholix, we understand the importance of timely and reliable delivery of nutritional
products. We partner with third-party logistics (3PL) providers to ensure that your orders are
processed efficiently and reach you in perfect condition.
<ul>
  <li><p><b>Efficient Fulfilment:</b> Our collaboration with 3PL providers allows us to streamline
inventory management and order processing, ensuring accurate and timely fulfilment
of your nutritional needs.</p> <br /></li>
  <li><p><b>Reliable Last Mile Delivery:</b> We leverage advanced routing technologies and
real-time tracking to ensure your nutrition products are delivered promptly and
securely.</p> <br /></li> 
                </ul>
</p>
                <br />
                <h5>Advanced Order Processing for Optimal Nutrition Delivery</h5>
                <p> At Wholix, our mission is to revolutionize the way people experience nutritional health by
offering a platform that connects consumers with a diverse range of high-quality, specialized
products. We aim to be the ultimate destination for all your nutrition needs, delivering an
unparalleled selection, exceptional service, and a commitment to enhancing health and
wellness worldwide.</p>
                <br />
                <h5>Our Vision </h5>
                <p> Wholix utilizes the latest technology to optimize order processing, ensuring your nutrition
                products are handled efficiently and accurately.
                <ul>
  <li><p><b>Automated Systems:</b> Our fulfilment centers are equipped with automated order
  management systems for real-time tracking and prompt order processing.</p> <br /></li>
  <li><p><b>Smart Labels Technology:</b> We use Smart Labels to maintain accurate inventory
  levels and reduce errors, ensuring your nutrition products are delivered accurately.</p> <br /></li>
  <li><p><b>Advanced Warehouse Management:</b> Our integrated Warehouse Management
Software (WMS) optimizes operations, enhancing the efficiency of order processing
and fulfilment.</p> <br /></li> 
<li><p><b>Data-Driven Insights:</b> We use data analytics to continuously refine our processes,
improving operational efficiency and customer satisfaction.</p> <br /></li> 
                </ul>
                
                </p>

                <h5>Why Choose Wholix for Your Nutritional Needs? </h5>
                <p>  
                <ul>
  <li><p><b>Comprehensive Product Range:</b> Access a diverse selection of specialized nutrition
  products that cater to every stage of life.</p> <br /></li>
  <li><p><b>Seamless Shopping Experience:</b> With Wholix, you can shop confidently knowing your
  nutritional needs will be met promptly and efficiently.</p> <br /></li>
  <li><p><b>Sustainable Practices:</b> Support eco-friendly delivery and community health through
  our commitment to sustainability.</p> <br /></li> 
<li><p><b>Authenticity and Trust:</b> We guarantee that all our products are genuine and
trustworthy. At Wholix, we only deal in authentic products, ensuring you receive
high-quality and safe nutrition solutions every time you shop.</p> <br /></li> 
<li><p><b>Wholix 5-Star Rating System:</b> Our Exclusive Wholix Nutritional Excellence has been
rigorously evaluated by our team of food technologists, dietitians, pediatricians, and
doctors. These experts ensure that every product meets the highest standards of quality
and nutritional value, giving you the confidence and peace of mind that you are
choosing the best for your health.</p> <br /></li> 
                </ul>
                
                </p>

            </article>
            <article>
                
            </article>

        </section>
        
        <Footer />

         </main>
    
    </> 
    
);
}

export default ProfileDeliveryLogistics;