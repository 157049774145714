
function PageNotFound() {
    return (

      <>
      <section className="slider_section ">  
      <center>
        <div className="preloader-wrap">
          <span> This will be updated soon.</span>
        

        </div>
           

      </center>
   </section>

      </>
   
    )
  }

  export default PageNotFound;