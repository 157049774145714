import { Link } from "react-router-dom";
import Header from "../header";
import Footer from "../footer";
import HomeSliderMain from "../addincluders/HomeSliderMain";
import ProfileNav from "./ProfileNav";
import { Helmet, HelmetProvider } from 'react-helmet-async';

function ProfileWholixNutritionalCenters () {
    return (
     <>
     <HelmetProvider>
         <Helmet>
             <title> Wholix Nutritional Centers: Specialized Nutrition for Every Life Stage | Pregnancy, Diabetes,
             Fitness, Mental Wellness &amp; More</title>
            <meta name="keywords" content="Wholix Nutritional Centers, pregnancy nutrition, baby food, diabetes management,
menstruation relief, menopause relief, fitness nutrition, sports supplements, fertility nutrition,
mental wellness, high protein foods, healthy food center, prenatal vitamins, low-glycemic
foods, hormonal balance, cognitive function, balanced nutrition"/>
            <meta name="description" content="Explore Wholix Nutritional Centers offering specialized products for every life stage. From
prenatal and baby nutrition to diabetes management, fitness supplements, fertility support,
mental wellness, and high protein foods, find curated selections to meet your specific
nutritional needs. Enjoy wholesome, nutrient-rich foods promoting overall health and
wellness."/>
             <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1"/>  
         </Helmet>
         </HelmetProvider>
     <main>
         <section className="head"> 
         <Header /> 
            <div className="container">
            <ProfileNav />
            
            </div> 
         </section> 
          
        <section className="content">
        <article className="container text-justify">
            <h4>WHOLIX NUTRITIONAL CENTERS : Specialized Nutrition for Every Need </h4>
            <hr />
                <p> Wholix Nutritional Centers offer specialized products catering to specific nutritional needs
                across various life stages:</p>
                <br /> 
                <ul>
                    <li>  <p><b>Pregnancy and Baby Food Center:<br /></b>Providing essential prenatal and baby nutrition,
including vitamins, supplements, and baby food supporting early development and
growth.  
                    </p> <br /></li>

                    <li>  <p><b>Diabetes Food Center:<br /></b>Dedicated to products supporting blood sugar management
                    and overall health, including low-glycemic foods and specially formulated snacks.
                    </p> <br /></li>
                    <li>  <p><b>Menstruation and Menopause Relief Center:<br /></b>Featuring foods and supplements to
alleviate symptoms and promote hormonal balance during menstruation and
menopause.
                    </p> <br /></li>
                    <li>  <p><b>Fitness and Sports Nutrition Center:<br /></b>Catering to athletes with a range of sports
supplements, protein powders, and recovery foods to enhance performance and
recovery.
                    </p> <br /></li>
                    <li>  <p><b>Wholix Fertility Center:<br /></b>Specialized nutrition for reproductive health, offering
nutrient-rich foods to support fertility for both men and women based on scientific
research.
                    </p> <br /></li>
                    <li>  <p><b>Wholix Mental Wellness Center:<br /></b>Curated selection of foods and supplements
supporting mental health and cognitive function, including omega-3 fatty acids and
antioxidants.
                    </p> <br /></li>
                    <li>  <p><b>High Protein Food Center:<br /></b>Offering protein-rich foods like powders, bars, and
                    snacks for muscle building, weight management, and balanced nutrition.
                    </p> <br /></li>
                    <li>  <p><b>Healthy Food Center:<br /></b>A variety of wholesome foods catering to all dietary
preferences and health goals, from organic produce to heart-healthy snacks promoting
overall wellness.
                    </p> <br /></li>
  

                </ul>

            </article>
            <article>
                
            </article>

        </section>
        
        <Footer />

         </main>
    
    </> 
    
);
}

export default ProfileWholixNutritionalCenters;