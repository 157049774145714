import { Link } from "react-router-dom";
import Header from "../header";
import Footer from "../footer";
import HomeSliderMain from "../addincluders/HomeSliderMain";
import ProfileNav from "./ProfileNav";
import { Helmet, HelmetProvider } from 'react-helmet-async';

function ProfileLeadershipPrinciples () {
    return (
     <>
     <main>
     <HelmetProvider>
         <Helmet>
             <title>Wholix Leadership Principles: Innovation, Quality, Curiosity, Global Impact &amp; Seamless
             Solutions</title>
            <meta name="keywords" content="Wholix leadership principles, customer-centric marketplace, quality food products, think big,
innovate, quality matters, learn and be curious, storage and logistics solutions, global impact,
sustainability, fair trade, food waste reduction, premium food delivery, food shopping
experience, global community, cutting-edge delivery systems"/>
            <meta name="description" content="Discover Wholix&#39;s core Leadership Principles: Think Big, Innovate, Quality Matters, Learn
&amp; Be Curious, and Leave a Global Impact. We provide top-quality food products, innovative
solutions, and a seamless shopping experience. Join us in creating a sustainable, customer-
centric food marketplace that benefits everyone."/>
             <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1"/>  
         </Helmet>
         </HelmetProvider>
         <section className="head"> 
         <Header /> 
            <div className="container">
            <ProfileNav />
            
            </div> 
         </section> 
          
        <section className="content">
        <article className="container text-justify">
            <h4>LEADERSHIP PRINCIPLES</h4>
            <hr />
                <p> At Wholix, we are dedicated to living out our core Leadership Principles every day. These principles
guide our actions, shape our unique culture, and drive us relentlessly towards our mission of being
the most customer-centric food marketplace, the best place to work, and the leader in quality and
innovation. Our commitment to these principles ensures we deliver an unparalleled food shopping
experience that meets the needs of our diverse community.</p>
                <br />
                <ul>
                    <li>  <p><b>Think Big :</b> At Wholix, we dream without boundaries and act with boldness. We envision a world where
everyone, regardless of location, has access to a diverse range of high-quality food products. Our
platform connects producers, sellers, and consumers from across the globe, breaking down barriers
and expanding horizons. With Wholix, you’re not just shopping; you’re joining a global community
that’s revolutionizing the food marketplace.</p> <br /></li>

<li>  <p><b>Innovate :</b> Innovation is the cornerstone of everything we do at Wholix. From discovering unique, hard-to-find
ingredients to developing cutting-edge delivery systems, we are constantly seeking ways to enhance
your food shopping experience. Our team is committed to exploring new technologies and methods
to ensure your journey with Wholix is seamless, enjoyable, and inspiring. We're here to bring you the
future of food, today.</p> <br /></li>

<li>  <p><b>Quality Matters:</b> Quality is our unwavering priority. Every product on Wholix undergoes stringent selection and
quality control processes. We partner with trusted farmers, artisans, and producers who share our
commitment to excellence. Whether you’re searching for organic produce, premium meats, or
gourmet treats, you can trust Wholix to deliver only the best to your doorstep.</p> <br /></li>
<li>  <p><b>Learn & Be Curious: </b>Curiosity is the engine of discovery, and at Wholix, we encourage you to explore and learn. Our
platform is more than just a marketplace; it’s a treasure trove of knowledge. Discover new recipes,
learn about exotic ingredients, and gain insights from food experts. We believe that every meal is an
opportunity to learn something new and delightful. Dive into the world of food with Wholix and
satisfy your curiosity.</p> <br /></li>

<li>  <p><b>Solution Provider for Your Storage & Logistics: </b>Managing food storage and logistics can be daunting, but Wholix simplifies the process. We offer
comprehensive solutions to ensure your purchases are stored and delivered under optimal
conditions. Our state-of-the-art logistics network guarantees that your orders arrive fresh and on
time, every time. From temperature-controlled storage to efficient delivery systems, we’ve got you
covered.</p> <br /></li>

<li>  <p><b>Leave a Global Impact: </b>At Wholix, we are committed to creating a positive impact on the world. We prioritize sustainability,
support fair trade practices, and work diligently to reduce food waste. By choosing Wholix, you’re
not just enjoying top-quality food but also contributing to a healthier planet and a fairer global food
system. Together, we can make a difference and leave a lasting impact for future generations.</p> <br /></li>
 

                </ul>
 

            </article>
            <article>
                
            </article>

        </section>
        
        <Footer />

         </main>
    
    </> 
    
);
}

export default ProfileLeadershipPrinciples;