import slide1 from '../images/productone.jpg';  
 
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from "react-router-dom";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Keyboard, Pagination, Navigation, breakpoints, Autoplay  } from 'swiper/modules';

function FeatureProductSlider() {
    return ( 
        <>
             <Swiper
         loop={true}
            slidesPerView={2} 
            breakpoints={{
                576: { 
                  slidesPerView: 2,
                },
                768: { 
                  slidesPerView: 3,
                },
                900: { 
                  slidesPerView: 4,
                },
              }}
            keyboard={{
              enabled: true,
            }}
            autoplay={{
                delay: 1500,
                disableOnInteraction: true
          }}
            pagination={{
              clickable: true,
            }}
            navigation={false}
            modules={[Autoplay, Keyboard, Pagination, Navigation]}
            className="mySwiper"
          >
        
            <SwiperSlide>

                 <div className="card">
                        <img className="card-img-top" src={ slide1 } alt="Card image cap" />
                        <div className="card-body">
                            <h5 className="card-title">Nutrition Pack</h5>
                            <p className="card-text"> Pure Nutrition Multivitamin for Women consists of vitamins</p> 
                            <br />
                            <div className="btn-group" role="group" aria-label="First group">
                                <Link type="button" className="btn btn-outline-light" to='/product-detail/'> <i className="fas fa-angle-right"></i> View</Link>
                                <button type="button" className="btn btn-outline-light"><i className="fas fa-cart-plus"></i></button>
                            
                                <button type="button" className="btn btn-outline-light"><i className="fas fa-heart"></i></button>
                            </div>
                        </div>
                        </div> 
            </SwiperSlide>
            <SwiperSlide>

<div className="card">
       <img className="card-img-top" src={ slide1 } alt="Card image cap" />
       <div className="card-body">
           <h5 className="card-title">Nutrition Pack</h5>
           <p className="card-text"> Pure Nutrition Multivitamin for Women consists of vitamins</p> 
           <br />
           <div className="btn-group" role="group" aria-label="First group">
               <Link type="button" className="btn btn-outline-light" to='/product-detail/'> <i className="fas fa-angle-right"></i> View</Link>
               <button type="button" className="btn btn-outline-light"><i className="fas fa-cart-plus"></i></button>
           
               <button type="button" className="btn btn-outline-light"><i className="fas fa-heart"></i></button>
           </div>
       </div>
       </div> 
</SwiperSlide>
<SwiperSlide>

<div className="card">
       <img className="card-img-top" src={ slide1 } alt="Card image cap" />
       <div className="card-body">
           <h5 className="card-title">Nutrition Pack</h5>
           <p className="card-text"> Pure Nutrition Multivitamin for Women consists of vitamins</p> 
           <br />
           <div className="btn-group" role="group" aria-label="First group">
               <Link type="button" className="btn btn-outline-light" to='/product-detail/'> <i className="fas fa-angle-right"></i> View</Link>
               <button type="button" className="btn btn-outline-light"><i className="fas fa-cart-plus"></i></button>
           
               <button type="button" className="btn btn-outline-light"><i className="fas fa-heart"></i></button>
           </div>
       </div>
       </div> 
</SwiperSlide>
<SwiperSlide>

<div className="card">
       <img className="card-img-top" src={ slide1 } alt="Card image cap" />
       <div className="card-body">
           <h5 className="card-title">Nutrition Pack</h5>
           <p className="card-text"> Pure Nutrition Multivitamin for Women consists of vitamins</p> 
           <br />
           <div className="btn-group" role="group" aria-label="First group">
               <Link type="button" className="btn btn-outline-light" to='/product-detail/'> <i className="fas fa-angle-right"></i> View</Link>
               <button type="button" className="btn btn-outline-light"><i className="fas fa-cart-plus"></i></button>
           
               <button type="button" className="btn btn-outline-light"><i className="fas fa-heart"></i></button>
           </div>
       </div>
       </div> 
</SwiperSlide>
<SwiperSlide>

<div className="card">
       <img className="card-img-top" src={ slide1 } alt="Card image cap" />
       <div className="card-body">
           <h5 className="card-title">Nutrition Pack</h5>
           <p className="card-text"> Pure Nutrition Multivitamin for Women consists of vitamins</p> 
           <br />
           <div className="btn-group" role="group" aria-label="First group">
               <Link type="button" className="btn btn-outline-light" to='/product-detail/'> <i className="fas fa-angle-right"></i> View</Link>
               <button type="button" className="btn btn-outline-light"><i className="fas fa-cart-plus"></i></button>
           
               <button type="button" className="btn btn-outline-light"><i className="fas fa-heart"></i></button>
           </div>
       </div>
       </div> 
</SwiperSlide>
            
             
          
      </Swiper>
        </>

     );
}

export default  FeatureProductSlider;