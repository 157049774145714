import wholixlogo from './images/wholixlogo.png';
import iconapp1 from './images/iconapp1.png';
import iconapp2 from './images/iconapp2.png';
import xicon from './images/xicon.png';
import { Link } from "react-router-dom"; 

function Footer () {
     return ( 
        <>
        

        <footer>
            <section className="mainfooter">
                <div className='container'>
                    <div className="row">
                        <div className="col-12 col-md-4">
                                <img src={ wholixlogo } style={{ maxWidth:"100px"} } />
                                <br />
                                <h3>Wholix India</h3>
                                <p>India's pioneering e-commerce platform dedicated to specialized health, wellness, and nutrition needs.</p>
                        </div>
                        <div className="col-12 col-md-4">
                            <br />
                        <h6>Get to Know Us</h6>
                        <br />
                        <ul className='listlink'> 
                            <li><Link  to="/our-profile/">Our Profile</Link></li> 
                            <li><Link  to="#">Press Releases</Link></li>
                            <li><Link  to="#">Careers</Link></li>  
                        </ul>
                        <br />
                        <h6>Let Us Help You</h6>    
                        <br />
                        <ul className='listlink'> 
                        <li><Link  to="#">Your Account</Link></li> 
                            <li><a href="/seller/" target="_blank">Become an Seller</a></li>
                            <li><Link  to="#">Help</Link></li> 
                        </ul>

                        </div>
                        <div className="col-12 col-md-4">
                        <br />
                            <div className='row'>

                                <div className='col-6'>
                                
                                <h6>Download App</h6> 
                                <br />
                                    <ul className='listlink'> 
                                        <li><Link  to="#"><img src={iconapp1} style={ { maxWidth:'150px'}}/></Link></li> 
                                        <li><Link  to="#"><img src={ iconapp2 } style={ { maxWidth:'150px'}} /></Link></li> 
                                    </ul>
                                    <br />

                                </div>
                                <div className='col-6'>

                                <h6>Connect with us</h6> 
                                <br />
                                    <ul className='listlink social'> 
                                    <Link  to="#"><i className="fab fa-facebook-f fa-lg"></i></Link>
                                    <Link  to="#"><i className="fab fa-instagram fa-lg"></i></Link>
                                    <Link  to="#"><img src={ xicon }  style={ { maxWidth:'22px'}}/></Link>
                                    <Link  to="#"><i className="fab fa-linkedin-in fa-lg"></i></Link>
                                    
                                    </ul>
                                    <br />
                                     <a href="https://blog.wholix.in/" target='_blank' className='buttonbrand'>Wholix Blog </a>  
                                    
                                </div>
                            </div>
                       
                       
                        </div>
                    </div>
            </div>
            </section> 
           
            <section  className='copyright'>
                <div className="container cotest">

                <p>© 2024, Wholix India. All Rights Reserved.</p>
                <p>Powered by Imperial Epicure LLP.</p>
                </div>
               
            </section>
         </footer>
       
        </>

      );
}

export default Footer;