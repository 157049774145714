import { Link } from "react-router-dom";
import wholix from './images/wholix.png';  
import Nav from "./addincluders/Nav"; 

function Header () {
    return (  
        <>
        <header>
            <div className="container">

                <div className="toper"> 
                    <p>For any query, email us at info@wholix.in or call us on +91-8920553930 &nbsp;&nbsp;<a href="#" className="trainingicon"> <i className="fas fa-award"></i>&nbsp;Training Programs </a>  </p>  
                </div>
          
                <div className="headcontent">
                    <div className="logo"><img src={ wholix }  /></div>
                    <div className="headright"> 
                    <div className="access md-flex md-flex-row md-justify-end items-center">  
                            <Link className="buttonbrand"  to="/login/">Login</Link>
                            <Link className="buttonbrand"  to="/signup/">Signup</Link>  
                            <Link className="buttonbrand cartbtn"  to="/signup/">Cart <i className="fas fa-shopping-cart"></i></Link> 
                    
                    </div>
                    <form className="searchbar">
                        <input className="form-control" type="text" placeholder="Search" aria-label="Search" />
                        <button><i className="fa fa-search" aria-hidden="true"></i></button>
                    </form> 

                    </div>
                    
                </div>
                
                

            </div>   

         </header>
        </>

    );
}

export default Header;