import { Link } from "react-router-dom";
import Header from "../header";
import Footer from "../footer";
import HomeSliderMain from "../addincluders/HomeSliderMain";
import ProfileNav from "./ProfileNav";
import { Helmet, HelmetProvider } from 'react-helmet-async';

function ProfileAboutusWhatWeOffer () {
    return (
     <>
     <HelmetProvider>
         <Helmet>
             <title>What We Offer at Wholix: Diverse Nutrition Products, Expert Curation, Seamless Shopping,
             and Sustainable Choices</title>
            <meta name="keywords" content="Wholix offerings, diverse nutrition products, pregnancy nutrition, baby nutrition, sports supplements, elderly dietary support, Wholix Health Score, curated selection, expert curation, seamless shopping experience, sustainable food system, ethical food choices, health and well-being, fair trade, small producers, reducing food waste" />
            <meta name="description" content="Explore Wholix&#39;s extensive range of expertly curated nutrition products, from pregnancy and
baby food to sports supplements and elderly support. Enjoy a seamless shopping experience
with our user-friendly platform, efficient logistics, and exceptional service. Support
sustainable, ethical food choices and make a positive impact on your health and the
environment."/>
             <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1"/>  
         </Helmet>
         </HelmetProvider>
     <main>
         <section className="head"> 
         <Header /> 
            <div className="container">
            <ProfileNav />

            </div> 
         </section> 
         
        <section className="content">
        <article className="container text-justify">
            <h4>WHAT WE OFFER</h4>
            <hr /> 
                <ul>
                    <li>  <p><b>Diverse Product Range:</b> Our extensive range of products caters to every taste and
nutritional need. From specialized pregnancy and baby nutrition to sports
supplements and elderly dietary support, we have something for everyone. Our
diverse selection ensures that you can find exactly what you need, no matter your
dietary requirements or culinary interests.</p> <br /></li>

<li>  <p><b>Wholix Health Score :</b> Our Health score rating system makes sure that quality is
uncompromised and each product is thoroughly vetted to meet our stringent quality
standards, supporting your health and well-being.</p> <br /></li>
<li>  <p><b>Expertly Curated Selection:</b> Our team of experts carefully curated our product
selection to bring you the best in quality and variety. We collaborate with trusted
suppliers and producers who share our commitment to excellence, ensuring that
every item on our platform meets our rigorous standards.</p> <br /></li>
<li>  <p><b>Seamless Shopping Experience: </b>We are dedicated to making your shopping
experience as seamless and enjoyable as possible. Our user-friendly platform,
efficient logistics, and exceptional customer service ensure that you can shop with
confidence and ease. Enjoy quick access to a vast array of healthy food options and
have your products delivered right to your doorstep. From browsing to delivery,
Wholix is here to make your nutritional shopping a breeze.</p> <br /></li>
<li>  <p><b>Impactful Choices: </b>By choosing Wholix, you’re supporting a more sustainable and
ethical food system. We are committed to reducing food waste, promoting fair
trade, and supporting small producers and farmers. Together, we can make a
positive impact on the world and contribute to a healthier, more sustainable future.
Make informed choices that benefit not only your health but also the environment and
the broader community.</p> <br /></li>
 

                </ul>
                


            </article>
            <article>
                
            </article>

        </section>
        
        <Footer />

         </main>
    
    </> 
    
);
}

export default ProfileAboutusWhatWeOffer;